import React from 'react';
import * as styles from './styles.scss';
import clsx from 'clsx';

type CurrencyVariants = {
    RUB: 'ru-RU';
};

type CurrencyTypes = keyof CurrencyVariants;
type CountryTypes = CurrencyVariants[CurrencyTypes];

type CurrencySymbolProps = {
    currency?: CurrencyTypes;
    country?: CountryTypes;
    price: number;
} & JSX.IntrinsicElements['span'];

const Price: React.FC<CurrencySymbolProps> = (props) => {
    const { currency = 'RUB', country = 'ru-RU', price, ...spanProps } = props;
    /*const _price = new Intl.NumberFormat(country, {
        style: 'currency',
        currency,
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(price);*/

    if (!price) return null;

    return (
        <span
            {...spanProps}
            className={clsx(styles.price, spanProps.className)}
        >
            {price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}&nbsp;₽
        </span>
    );
};

export default Price;
