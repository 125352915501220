import React, { useRef, useEffect, useState } from 'react';
import { omit } from 'lodash';

type ImageOnLoadProps = {
    src: string;
    onLoad?: (image: HTMLImageElement) => void;
} & JSX.IntrinsicElements['img'];

const ImageOnLoad: React.FC<ImageOnLoadProps> = (props) => {
    const [isLoaded, setLoaded] = useState(false);
    const imgRef = useRef<HTMLImageElement>(null);
    const srcRef = useRef('');
    const restProps = omit(props, 'onLoad');

    const onLoad = (image: HTMLImageElement | null) => {
        if (isLoaded || !image) return;
        setLoaded(true);
        props.onLoad && props.onLoad(image);
    };

    useEffect(() => {
        const image = imgRef.current;

        if (srcRef.current !== props.src) {
            srcRef.current = props.src;
            setLoaded(false);
        }

        if (image && image.complete) {
            setLoaded(true);
            props.onLoad && props.onLoad(image);
        }

        return () => {
            setLoaded(false);
        };
    });

    return (
        <img
            ref={imgRef}
            onLoad={(event) => {
                event.persist();
                onLoad(event.nativeEvent.target as HTMLImageElement);
            }}
            {...restProps}
        />
    );
};

export default ImageOnLoad;

// class InputOnLoad extends React.Component<Props> {
//     static propTypes = {
//       src: PropTypes.string,
//       onLoad: PropTypes.func.isRequired, // (image: HTMLImageElement) => Promise<void> | void,
//     };
//     componentDidMount() {
//       const { onLoad } = this.props;
//       const { image } = this;

//       if (image && image.complete) {
//         this.isLoaded = true;
//         onLoad(image);
//       }
//     }
//     componentWillReceiveProps(nextProps: Props) {
//       if (nextProps.src !== this.props.src) {
//         this.isLoaded = false;
//       }
//     }
//     componentWillUnmount() {
//       this.isLoaded = false;
//     }
//     onLoad = (e: any) => {
//       if (this.isLoaded) return;
//       this.isLoaded = true;
//       this.props.onLoad(e.target);
//     };
//     onRef = (image: HTMLImageElement) => {
//       this.image = image;
//     };
//     image: ?HTMLImageElement;
//     isLoaded: ?boolean;
//     props: Props;
//     render() {
//       const { onRef, onLoad } = this;
//       const otherProps: Object = omit(['onLoad'])(this.props);

//       return <img ref={onRef} onLoad={onLoad} {...otherProps} />;
//     }
//   }
