import React from 'react';
import * as styles from './styles.scss';
import { Link } from '@src/components/Link';
import { CatalogState } from '@store/ducks/catalog';
import { CONTEXT } from '@vsemayki/url-resolver';
import ReactPlaceholder from 'react-placeholder';
import ImageOnLoad from '@uikit/ImageOnLoad';
import Price from '@uikit/Price';
import clsx from 'clsx';

// TODO: rework component to work all as skeleton for faster TTFB
type CatalogProps = { item: CatalogState['items'][0]; isHide?: boolean };
const CatalogItem: React.FC<CatalogProps> = (props) => {
    const CatalogItem = props.item;
    const haveDiscount = CatalogItem.old_price !== 0;
    const [imgIsLoaded, setImgLoaded] = React.useState(false);

    return (
        <div
            className={styles.catalogItem}
            data-autotest="CatalogItem"
            title={props.item.title}
        >
            <Link
                context={{
                    name: CONTEXT.article,
                    article: {
                        type: CatalogItem.category.id,
                        design: CatalogItem.design,
                    },
                    query: {
                        color: CatalogItem.relations.color,
                    },
                }}
                nativeLinkProps={{ className: styles.itemLink }}
            >
                <div
                    className={clsx(
                        styles.productImg,
                        !imgIsLoaded && styles.placeholderBox
                    )}
                >
                    <ReactPlaceholder
                        type="rect"
                        showLoadingAnimation
                        ready={imgIsLoaded}
                        className={styles.placeholder}
                        style={{
                            backgroundColor: 'inherit',
                        }}
                    >
                        <span></span>
                    </ReactPlaceholder>
                    <ImageOnLoad
                        onLoad={() => setImgLoaded(true)}
                        src={
                            CatalogItem.sides[
                                CatalogItem.main_side_position ?? 0
                            ]?.big
                        }
                        alt={CatalogItem.title}
                        className={clsx(
                            styles.preview,
                            !imgIsLoaded && styles.previewHidden
                        )}
                    />
                </div>
                <div className={styles.itemDescription}>
                    {!props.isHide && (
                        <div className={styles.title}>{CatalogItem.title}</div>
                    )}
                    <div
                        className={clsx(
                            styles.category,
                            props.isHide && styles.hover
                        )}
                    >
                        {CatalogItem.category.title}
                    </div>
                    <div className={styles.prices}>
                        <Price
                            price={CatalogItem.price}
                            className={
                                haveDiscount ? styles.newPrice : styles.price
                            }
                        />
                        {haveDiscount && (
                            <Price
                                price={CatalogItem.old_price}
                                className={styles.oldPrice}
                            />
                        )}
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default CatalogItem;
